<template>
  <div class="container-fluid">
    {{ employee_background }}
    <!-- {{ hh }} -->
    <!-- {{ stringWithoutTags }} -->
    <div class="row">
      <div class="col-lg-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Individual Orientation Assessment Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="indOri">
          <div class="card-body">
            <h3 class="mt-3" v-if="pdfLoading">
              Individual Orientation Assessment
            </h3>
            <div class="d-flex" v-if="!pdfLoading">
              <label class="col-form-label me-1">Program Name</label>
              <div style="width: 230px">
                <v-select
                  label="name"
                  :options="programs"
                  v-model="program"
                  :clearable="false"
                  @option:selected="getBatchByProgram(program)"
                  :selectable="(options) => options.id != program.id"
                >
                </v-select>
              </div>

              <label class="col-form-label me-1 ms-3">Batch</label>
              <div style="width: 150px">
                <v-select
                  :clearable="false"
                  label="batch_no"
                  :options="batches"
                  v-model="batch"
                  :selectable="(options) => options.id != batch.id"
                  @option:selected="getAssessorsList(batch)"
                >
                </v-select>
              </div>

              <label class="col-form-label me-1 ms-3">Assessor</label>
              <div style="width: 150px">
                <v-select
                  :clearable="false"
                  label="assessor_name"
                  :options="accessors"
                  v-model="accessor"
                  :selectable="
                    (options) => options.assessor_id != accessor.assessor_id
                  "
                  @option:selected="getEmployeeList(accessor)"
                >
                </v-select>
              </div>

              <label class="col-form-label me-1 ms-3">Employee Name</label>
              <div style="width: 150px">
                <v-select
                  :clearable="false"
                  label="employee_name"
                  v-model="employee"
                  :options="employeeLists"
                  :selectable="
                    (options) => options.employee_id != employee.employee_id
                  "
                  @option:selected="getIndividualDetailByEmployee(employee)"
                ></v-select>
              </div>

              <div @click="refreshData" class="icon-css">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>
            <div
              class="mt-4"
              v-show="
                !loading &&
                Object.keys(individual_orientation_assessment).length != 0
              "
            >
              <h5 class="mb-3 p-2 text-white" style="background-color: gray">
                Employee Information
              </h5>
              <div class="row">
                <div class="col-6">
                  <div>
                    Employee Name :
                    <span class="fw-bold">{{
                      individual_orientation_assessment?.employee_name
                    }}</span>
                  </div>
                  <div>
                    Employee ID Number :
                    <span class="fw-bold">{{
                      individual_orientation_assessment?.employee_id
                    }}</span>
                  </div>
                  <div>
                    Date of Join :
                    <span class="fw-bold">{{
                      individual_orientation_assessment?.join_date
                    }}</span>
                  </div>
                  <div>
                    Program Name :
                    <span class="fw-bold">{{
                      individual_orientation_assessment?.program_short_name
                    }}</span>
                  </div>
                  <div>
                    Program Batch Name :
                    <span class="fw-bold">{{
                      individual_orientation_assessment?.program_batch_no
                    }}</span>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    Orientation Batch No :
                    <span class="fw-bold">{{
                      individual_orientation_assessment?.ori_batch
                    }}</span>
                  </div>
                  <div>
                    Department :
                    <span class="fw-bold">{{
                      individual_orientation_assessment?.department?.name
                    }}</span>
                  </div>
                  <div>
                    Assesssor Name :
                    <span class="fw-bold">{{
                      individual_orientation_assessment?.assessor_employee_name
                    }}</span>
                  </div>
                  <div>
                    Assessment Date :
                    <span class="fw-bold">{{
                      individual_orientation_assessment?.date
                    }}</span>
                  </div>
                  <div>
                    Holding Business :
                    <span class="fw-bold">{{
                      individual_orientation_assessment?.business_unit?.name
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                Assessment Criteria => Excellent = 5 , Exceed Expection = 4 ,
                Meet Expection = 3 , Need Improvement = 2 , Poor = 1
              </div>

              <div class="mt-3">
                <h5 class="p-2 mb-0 text-white" style="background-color: gray">
                  Employee Background
                </h5>
                <quill-editor
                  theme="snow"
                  v-model:content="employee_background"
                  :toolbar="toolbarOptions"
                  contentType="html"
                  style="height: 200px; pointer-events: none;"
                  
                ></quill-editor>
              </div>

              <div class="mt-3">
                <h5 class="p-2 mb-0 text-white" style="background-color: gray">
                  HRD Feedback
                </h5>
                <quill-editor
                 
                  theme="snow"
                  v-model:content="hrd_feedback"
                  :toolbar="toolbarOptions"
                  contentType="html"
                  style="height: 200px; pointer-events: none"
               
                ></quill-editor>
              </div>

              <div class="mt-3">
                <h5 class="p-2 mb-0 text-white" style="background-color: gray">
                  HRD Orientation Assessment
                </h5>
                <table class="">
                  <thead>
                    <tr>
                      <th class="py-2 text-center" style="width: 100px">No.</th>
                      <th class="py-2 text-center" style="width: 300px">
                        Assessment Points
                      </th>
                      <th class="py-2 text-center" style="width: 100px">
                        Scores
                      </th>
                      <th class="py-2 text-center" style="width: 70%">
                        Detail Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        assessment, index
                      ) in individual_orientation_assessment?.hrd_orientation_assessment?.hrd_assessment_details"
                    >
                      <td class="text-center">{{ ++index }}</td>
                      <td class="ps-2">
                        {{ assessment?.hrd_criterias?.name }}
                      </td>
                      <td class="text-center">{{ `${assessment?.score} ` }}</td>
                      <td class="ps-2">{{ assessment?.remark }}</td>
                    </tr>
                    <tr>
                      <td class="py-1 text-center" colspan="2">Total Score</td>
                      <td class="text-center">
                        {{
                          `${individual_orientation_assessment?.orientation_total_score} %`
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="mt-3">
                <h5 class="p-2 mb-0 text-white" style="background-color: gray">
                  Behavior Survey Results
                </h5>
                <table class="">
                  <thead>
                    <tr>
                      <th class="py-2 text-center" style="width: 100px">No.</th>
                      <th class="py-2 text-center" style="width: 350px">
                        Assessment Points
                      </th>
                      <th class="py-2 text-center" style="width: 70%">
                        Result
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        behavior_survey_results, index
                      ) in individual_orientation_assessment?.behavior_survey_results"
                    >
                      <td class="text-center py-1">{{ ++index }}</td>
                      <td class="ps-2 py-1">
                        {{ behavior_survey_results?.hrd_behavior_answers?.name }}
                      </td>
                      <td class="text-center py-1">
                        {{
                          behavior_survey_results?.hrd_behavior_answers?.score
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="py-1 text-center">Total Score</td>
                      <td class="text-center">
                        {{
                          individual_orientation_assessment?.behavior_survey_total_score
                        }}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="py-1 text-center">Achievement</td>
                      <td class="text-center">
                        {{ individual_orientation_assessment?.achievement }} %
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="py-1 text-center">Weight</td>
                      <td class="text-center">
                        {{ individual_orientation_assessment?.total_weight }}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="py-1 text-center">Result</td>
                      <td class="text-center">
                        {{
                          individual_orientation_assessment?.evaluation_score
                        }}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="py-1 text-center">
                        HRD Recommend
                      </td>
                      <td class="text-center">
                        {{
                          individual_orientation_assessment?.assessment_result
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import axios from "axios";
import { useToast } from "vue-toastification";
import { exportExcel } from "../../../utlis/excel-export-utlis";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      pdfLoading: false,
      excelLoading: false,
      loading: false,
      programs: this.$store.getters["odoo/getAllPrograms"],

      batches: [],
      accessors: [],
      employeeLists: [],
      program: "",
      batch: "",
      accessor: "",
      employee: "",
      employee_background: "",
      hrd_feedback: "",
      toolbarOptions: [],
      individual_orientation_assessment: {},
      employee_information: {},
      baseUrl: process.env.VUE_APP_BASE_URL,
       
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    async getBatchByProgram(program) {
      this.batch = "";
      this.accessor = "";
      this.employee = "";
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/program-batches/list?program_id=${program.id}`
        )
        .then((response) => {
          this.batches = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getAssessorsList(batch) {
      this.loading = true;
      this.accessor = "";
      this.employee = "";
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/get-assessor-list/${this.program.id}/${batch.id}`
        )
        .then((response) => {
          this.accessors = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getEmployeeList(accessor) {
      this.employee = "";
      this.employeeLists = accessor.employees;
    },
    async getIndividualDetailByEmployee(employee) {
      if (employee) {
        this.loading = true;
        this.$Progress.start();

        await axios
          .get(
            `${this.baseUrl}admin/v2/individual-orientation-assessment-list-report/${this.program.id}/${this.batch.id}/${employee.employee_id}`
          )
          .then((response) => {
            if (response.status == 200) {
              this.individual_orientation_assessment = response.data.data;
              this.employee_background =
                this.individual_orientation_assessment?.employee_background;
              this.hrd_feedback =
                this.individual_orientation_assessment?.hrd_feedback;
              this.$Progress.finish();
            }
          })
          .catch(() => {
            this.toast.error("Something went wrong.");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    exportExcelClick() {
      if (Object.keys(this.individual_orientation_assessment).length == 0)
        return this.toast.error("There is no data to export!");
      const title = "Individual Orientation Assessment Report";
      const data = this.individual_orientation_assessment;
      const sheetData = [];
      const header1 = [
        "Employee ID",
        "Employee Name",
        "Date of Join",
        "Program Name",
        "Program Batch No",
        "Orientation Batch No",
        "Department",
        "Business Unit",
        "Assessor Name",
        "Assessment Date",
        "Employee Background",
        "HRD Feedback",
      ];

      const header2 = ["No", "Assessment Point", "Score", "Detail Description"];
      const header3 = ["No", "Assessment Point", "Result"];

      sheetData.push(
        header1,
        [
          data.employee_id,
          data.employee_name,
          data.join_date,
          data.program_name,
          data.program_batch_no,
          data.batch,
          data.department.name,
          data.business_unit.name,
          data.assessor_employee_name,
          data.date,
          this.employee_background
            ? this.stripHtmlTags(this.employee_background)
            : "-",
          this.hrd_feedback ? this.stripHtmlTags(this.hrd_feedback) : "-",
        ],
        [],
        [],
        [], //this is spacing like my
        header2
      );

      let i = 0;
      data.hrd_orientation_assessment.hrd_assessment_details.forEach((item) => {
        const loopItem = [
          ++i,
          item.hrd_criterias.name,
          item.score,
          item.remark,
        ];
        sheetData.push(loopItem);
      });
      sheetData.push(
        [
          "Total Scores",
          data.hrd_orientation_assessment?.hrd_score + " %",
        ],
        [],
        [],
        []
      );

      sheetData.push(header3);
      let r = 0;
      data.behavior_survey_results.forEach((item) => {
        const loopItem = [
          ++r,
          item.hrd_behavior_answers.name,
          item.hrd_behavior_answers.score,
        ];
        sheetData.push(loopItem);
      });
      sheetData.push(
        ["Total Scores", data.behavior_survey_results[0]?.hrd_score + " %"],
        ["Achievement", data?.achievement + " %"],
        ["Weight", data?.total_weight + " %"],
        ["Result", data?.evaluation_score + " %"],
        ["HRD Recommend	", data?.assessment_result]
      );
      exportExcel(sheetData, title);
    },
    stripHtmlTags(str) {
      if (str === null || str === "") {
        return false;
      }
      return str.replace(/<[^>]*>/g, "");
    },
    exportPdfClick() {
      this.pdfLoading = true;
      setTimeout(() => {
        const ori = document.getElementById("indOri");
        html2canvas(ori, {
          logging: true,
          letterRendering: 1,
          useCORS: true,
        }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL("img/png");
          const pdf = new jsPDF("p", "mm", "a4");
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
          pdf.save("Individual Orientation Assessment.pdf");
        });
        this.pdfLoading = false;
      }, 1000);
    },
    refreshData() {
      this.program = "";
      this.batch = "";
      this.employee = "";
      this.accessor = "";
      this.batches = [];
      this.accessors = [];
      this.programs = [];
      this.employeeLists = [];

    },
  },
  created() {
    this.getIndividualDetailByEmployee();
    
  },
};
</script>
<style scoped>
table,
th,
td {
  border: 1px solid;
}
</style>
